import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import StandardOutboundLink from './standard-outbound-link';

const GalleryImages = () => (
  <StaticQuery
    query={graphql`{
      allFile(filter: {absolutePath: {regex: "/(code-is-sport)/.*\\.jpg$/"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(height: 600, layout: CONSTRAINED)
            }
            publicURL
          }
        }
      }
    }`}
    render={(data) => {
      const images = data.allFile.edges.map((edge) => edge.node);
      return (
        <section style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
          {images.map((image) => (
            <div
              key={image.publicURL}
              style={{
                display: 'grid', gridTemplateColumns: '1fr', alignItems: 'end', justifyItems: 'center',
              }}
            >
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                title="Photo image"
                alt="Photo"
                style={{
                  width: '100%', maxWidth: '80%', maxHeight: '250px', padding: '10px', marginBottom: '0px',
                }}
              />
              <StandardOutboundLink href={image.publicURL}>
                See Full Size Image
              </StandardOutboundLink>
              {/* This a tag downloads the image */}
              <a
                href={image.publicURL}
                download={image.publicURL.split('/').pop()}
                style={{
                  marginBottom: '30px',
                }}
              >
                Download Image
              </a>
            </div>
          ))}
        </section>
      );
    }}
  />
);

export default GalleryImages;
