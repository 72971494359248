import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import StandardOutboundLink from './standard-outbound-link';

const LogoImages = () => (
  <StaticQuery
    query={graphql`{
      allFile(
        filter: {absolutePath: {regex: "/(logos)/.*\\.(gif|jpe?g|tiff?|png|webp|bmp)$/"}}
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(height: 600, layout: CONSTRAINED)
            }
            publicURL
          }
        }
      }
    }
    `}
    render={(data) => {
      const images = data.allFile.edges.map((edge) => edge.node);
      return (
        <>
          <p>
            Here are some common versions of our logo used by our partners.
          </p>
          <p>
            Looking for another version of our logo like an svg or something larger?
            We can probably get you exactly what you need if you reach out to us at
            marketing@codechampionship.com
          </p>
          <section style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
            {images.map((image) => (
              <div
                key={image.publicURL}
                style={{
                  display: 'grid', gridTemplateColumns: '1fr', alignItems: 'end', justifyItems: 'center',
                }}
              >
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  title="Photo image"
                  alt="Photo"
                  style={{
                    width: '100%', maxWidth: '80%', maxHeight: '250px', padding: '10px', marginBottom: '0px',
                  }}
                />
                <StandardOutboundLink href={image.publicURL}>
                  See Full Size Image
                </StandardOutboundLink>
                {/* This a tag downloads the image */}
                <a
                  href={image.publicURL}
                  download={image.publicURL.split('/').pop()}
                  style={{
                    marginBottom: '30px',
                  }}
                >
                  Download Image
                </a>
              </div>
            ))}
          </section>
        </>
      );
    }}
  />
);

export default LogoImages;
