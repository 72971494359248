import React from 'react';
import Layout from '../components/layout';
import ShareVideo from '../components/share-video';
import SEO from '../components/seo';
import GalleryImages from '../components/gallery-images';
import LogoImages from '../components/logo-images';

const Gallery = () => (
  <Layout>
    <SEO
      title="Code Championship Gallery | Promotional Material for Code Championship"
      description="Marketing and promotional material - videos and images - for Code Championship."
      keywords={['marketing', 'promotional', 'images', 'videos', 'gallery']}
    />
    <h1>Code Championship Gallery</h1>
    <div className="stripe-hr thin" />
    <h2>Code Championship Promotional Gallery</h2>
    <div className="two-wide-container">
      <p>
        The images and videos provided here are for promotional use
        for Code Championship events. Partners are free to
        use these to promote Code Championship events.
      </p>
      <p>
        If you would like to use these images and videos for other purposes,
        or if you have any questions about these materials,
        please reach out to marketing@codechampionship.com
      </p>
    </div>
    <h2>Logos</h2>
    <LogoImages />
    <ShareVideo
      title="Code is Sport"
      videoId="p_i76rgLKXU"
    />
    <ShareVideo
      title="What is Code Championship?"
      videoId="ks80WhgW294"
    />
    <ShareVideo
      title="Inside an Event"
      videoId="tUj_W5jH1H4"
    />
    <ShareVideo
      title="Minnedemo Event"
      videoId="OL2noRzAvxQ"
    />
    <ShareVideo
      title="How is coding like basketball?"
      videoId="01zImPTar-o"
    />
    <ShareVideo
      title="How is coding like dance?"
      videoId="yT5WTilYH5s"
    />
    <ShareVideo
      title="How is coding like running?"
      videoId="HkZmpSxeOw8"
    />
    <ShareVideo
      title="How is coding like karate?"
      videoId="zhNPJ4dUkJo"
    />
    <GalleryImages />
  </Layout>
);

export default Gallery;
