import React, { useState } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import VideoSection from './video-section';

const ShareVideo = ({ videoId, title }) => {
  const [loop, setLoop] = useState(false);

  const videoQuery = queryString.stringify({
    rel: loop ? undefined : '0',
    loop: loop ? '1' : undefined,
    modestbranding: '1',
    playlist: loop ? videoId : undefined,
  });

  const videoString = `${videoId}?${videoQuery}`;
  const embedUrl = `https://www.youtube.com/embed/${videoString}`;
  const shareUrl = `https://youtu.be/${videoString}`;
  return (
    <>
      <h2>{`${title} Video`}</h2>
      <div className="two-wide-container" style={{ padding: '20px' }}>
        <div>
          <p>To share this video, here are a few links.</p>
          <p>
            If you are sharing this video, we recommend sending this link.
            The part at the end &quot;?rel=0&quot; will make it so that
            the video does not show other video recommendations at the end.
          </p>
          <div>
            <input
              type="checkbox"
              onChange={() => setLoop(!loop)}
              checked={loop}
            />
            Loop Video
          </div>
          <pre style={{ width: '100%', whiteSpace: 'pre-wrap' }}>
            {shareUrl}
          </pre>
          <p>If you would like to embed the video, we recommend the following</p>
          <pre style={{ width: '100%', whiteSpace: 'pre-wrap' }}>
            {`<iframe width="560" height="315" src="${embedUrl}" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />`}
          </pre>
        </div>
        <div>
          <VideoSection
            title={title}
            src={embedUrl}
          />
        </div>
      </div>
    </>
  );
};

ShareVideo.propTypes = {
  videoId: PropTypes.string,
  title: PropTypes.string,
};

ShareVideo.defaultProps = {
  videoId: 'p_i76rgLKXU',
  title: 'Code Championship Pump Up',
};

export default ShareVideo;
